import * as React from 'react'
import data from '../assets/swagger.json';
import {RedocStandalone, SideNavStyleEnum} from 'redoc';

const Api = (props: {}) => {
    return (
        <RedocStandalone spec={data}

                         onLoaded={error => {
                             if (!error) {
                                 console.log('Yay!');
                             } else {
                                 console.log('Errror!');
                             }
                         }}
                         options={{
                             nativeScrollbars: true,
                             hideHostname: true,
                             hideDownloadButton: false,
                             downloadFileName: 'api-sorewards',
                             hideSingleRequestSampleTab: true,
                             theme: {
                                 colors: {
                                     primary: {main: '#204788'}
                                 },
                                 rightPanel: {
                                     // width: '300px'
                                 }
                             },
                             scrollYOffset: 'header',
                             sideNavStyle: SideNavStyleEnum.IdOnly,
                             menuToggle: true,
                         }}/>
    )
}

export default Api
